import styled from 'styled-components'
import {BASE_CONF} from 'components/common/CPTheme'

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;

  @media screen and ${BASE_CONF.screens.lg} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`
