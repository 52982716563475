import styled from 'styled-components'

export const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;

  > div {
    border-color: ${props =>
      props.color
        ? `${props.color} transparent transparent transparent`
        : '#ffffff transparent transparent transparent'};
  }

  > div:nth-child(1) {
    animation-delay: -0.45s;
  }

  > div:nth-child(2) {
    animation-delay: -0.3s;
  }

  > div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes cp-loader-spinner-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Content = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  border: 3px solid;
  border-radius: 50%;
  animation: cp-loader-spinner-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
`
