import React from 'react'

import * as S from './styled'

function Spinner({color = '#ffffff', className}) {
  return (
    <S.Wrapper color={color} className={className}>
      <S.Content />
      <S.Content />
      <S.Content />
      <S.Content />
    </S.Wrapper>
  )
}

export default Spinner
